@import '@fontsource/roboto/300.css'
@import '@fontsource/roboto/400.css'
@import '@fontsource/roboto/500.css'
@import '@fontsource/roboto/700.css'
@import './overrides.sass'

body, #root
  margin: 0
  background-color: rgb(240, 242, 245)
  height: 100vh
  overflow: auto

html
  height: 100vh
  overflow: hidden

.is-fullheight
  height: 100vh

.take-fullheight
  height: 100%

.is-fullwidth
  width: 100%

.button
  font-weight: 500

.is-block
  display: block !important

.has-text-centered
  text-align: center

.mb-5
  margin-bottom: 2rem

.headerCell
  border-bottom: 2px solid #8b8b8b!important
  color: #1c1b1b
